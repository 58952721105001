<template>
  <div class="columns has-tex-centered">
    <div class="column is-10 is-offset-1 has-tex-centered">
      <iframe
        id="serviceFrameSend"
        src='TermsAndConditions.htm'
        width="100%"
        height="600"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Terms',
  }
</script>
